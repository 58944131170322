.recipe {
    height: 75px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    margin-left:28px;
}

.recipe.recipe-detail {
    height: 150px;
}

.recipe img {
    margin: 0 auto;
    display: block;
}

.recipe span {
    margin-top: 4px;
    text-align: center;
    display: block;
}

